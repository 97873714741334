<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16 elevation-10"
            locale="pt-pt"
        >
            <template v-slot:item.total="{item}">
                {{getTotal(item)}}
            </template>

  
        </v-data-table>
    </v-container>
</template>
<script>
import Collaborator from "@/api/Collaborator.js";

export default{
    name: "WorkersHours",
    watch: {
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.searchCollaborator()}, 10);
        },
        deep: true,
        },
    },
    data(){
        return{
            items: [],
            total: 0,
            loading: false,
            options: {},
            tableFooter: {
            'items-per-page-options': [5, 10, 15, 20],
            'items-per-page-text': "Linhas por página"
            },
            headers:[
                {
                    text: 'Nome',
                    sortable: false,
                    value: 'collaborator_object.full_name',
                },
                {
                    text: 'Jan.',
                    sortable: false,
                    value: 'jan',
                },    
                {
                    text: 'Fev.',
                    sortable: false,
                    value: 'feb',
                },  
                {
                    text: 'Mar.',
                    sortable: false,
                    value: 'mar',
                },  
                {
                    text: 'Abr.',
                    sortable: false,
                    value: 'apr',
                },  
                {
                    text: 'Mai.',
                    sortable: false,
                    value: 'mai',
                },  
                {
                    text: 'Jun.',
                    sortable: false,
                    value: 'jun',
                },  
                {
                    text: 'Jul.',
                    sortable: false,
                    value: 'jul',
                },  
                {
                    text: 'Ago.',
                    sortable: false,
                    value: 'aug',
                },  
                {
                    text: 'Set.',
                    sortable: false,
                    value: 'sep',
                },  
                {
                    text: 'Out.',
                    sortable: false,
                    value: 'oct',
                },  
                {
                    text: 'Nov.',
                    sortable: false,
                    value: 'nov',
                },  
                {
                    text: 'Dez.',
                    sortable: false,
                    value: 'dec',
                },     
                {
                    text: 'Total',
                    sortable: false,
                    value: 'total',
                },        
            ],
            filter:{
                year: null
            }
        }
    },
    mounted(){
        let dt = new Date();
            this.filter.year = dt.getFullYear()
            console.log(this.filter)

        if(!this.$root.session.hasPermission(["super", "collaborators.read"])) {
        this.$router.push('/admin');
        }

        this.fillBaseData();
    },
    methods:{
        getTotal(item){
            let total = 0;

            total = item.jan + item.feb + item.mar + item.apr + item.mai + item.jun + item.jul + item.aug + item.sep + item.oct + item.nov + item.dec

            return total
        },
        fillBaseData() {

            if(localStorage["collaborators-hours-list-" + this.$root.session.id] != undefined){

            let data = JSON.parse(localStorage["collaborators-hours-list-" + this.$root.session.id]);

                this.filter = data.filter;

                this.options = data.options;
            }
        },
        searchCollaborator(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }

            this.loading = true;

            

            let filter = {...this.filter};

            let request = {
                page: this.options.page,
                limit: this.options.itemsPerPage,
            };
            
            Object.assign(request, filter);

            localStorage["collaborators-hours-list" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            Collaborator.searchHours(request).then(response => {
                
                let data = response.data;

                this.total = data.total;

                this.items = data.data.map((item) => {

                this.searching = false;

                return item;
                });

                this.loading = false;

            });
        },
        resetCollaborator()
        {
            this.filter = {};

            this.searchCollaborator();
        },
    }
}
</script>