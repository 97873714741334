<template>
    <div>
      <h1 class="primary--text mt-4 ml-4 mb-n12">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>Faturação</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  @click="openDialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >

          <template v-slot:item.expires_at="{item}">
            {{ formatData(item.expires_at) }}
            <Tooltip
              v-if="showWarningIcon(item.expires_at) && item.paid == false"
              class="my-4 mr-4"
              color="red"
              text="Data expirada ou a expirar"
              top
            >
            <v-icon
              color="red"
              >mdi-alert-circle-outline</v-icon>
          </Tooltip>
          </template>


          <template v-slot:item.paid="{item}">
            <Tooltip
              v-if="item.paid"
              class="my-4 mr-4"
              color="green"
              text="Paga"
              top
            >
            <v-icon
              color="success"
            >mdi-check</v-icon>
            </Tooltip>
            <Tooltip
              v-else
              class="my-4 mr-4"
              color="red"
              text="Por pagar"
              top
            >
            <v-icon
              color="red"
              >mdi-alert-circle-outline</v-icon>
            </Tooltip>

          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewInvoice(item)"
            />
            <IconRemove
              
              class="mr-2"
              @on-submit="deleteInvoice(item)"
            />
          </template>
        </v-data-table>
        <AddInvoice ref="AddInvoice" :tenant="tenant" @reloadInvoices="searchInvoice"/>
        <EditInvoice ref="EditInvoice" :tenant="tenant" @reloadInvoices="searchInvoice" :selectedInvoice="selectedInvoice" method="update"/>
      </div>
    </div>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Invoice from "@/api/Invoice.js";
import AddInvoice from "@/components/clients/invoices/AddInvoice"
import EditInvoice from "@/components/clients/invoices/AddInvoice"
import Tooltip from "@/components/ui/Tooltip"

export default {
  components: {
    IconRemove,
    IconView,
    AddInvoice,
    Tooltip,
    EditInvoice
  },
  props:['tenant'],
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchInvoice()}, 10);
      },
      deep: true,
    },
  },
  data: () => ({
    selectedInvoice: null,
    headers: [
      {
        text: 'Título',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Descrição',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Expira a',
        sortable: false,
        value: 'expires_at',
      },
      {
        text: 'Total',
        sortable: false,
        value: 'total',
      },
      {
        text: 'Paga',
        sortable: false,
        value: 'paid',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      paid:false,
    },
    users:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "clients.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    showWarningIcon(dt){
      var fDt = new Date(dt);
      var today = new Date();
      var Difference_In_Time = fDt.getTime() - today.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if(Difference_In_Days <= 7)
        return true
      return false
    },
    openDialog(){
      this.$refs.AddInvoice.openDialog()
    },
    formatData(dt){
      return dt.split(' ')[0]
    },
    fillBaseData() {

      if(localStorage["invoices-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["invoices-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchInvoice(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["invoices-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Invoice.search(request, this.tenant).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetInvoice()
    {
      this.filter = {};

      this.searchInvoice();
    },
    viewInvoice(item){
      this.selectedInvoice = item
      this.$refs.EditInvoice.openDialog()
    },
    deleteInvoice(item){
      Invoice.delete(item, this.tenant)
          .then(() => {
            this.searchInvoice();
          });
    },
  },
  computed: {
    
  }
};
</script>
