<template>
  <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <span>Bem-vindo {{ name }}</span>
      </h1>
      <v-container v-if="!loading">
        <v-row>
          <p class="font-italic text-h4 mt-4">{{ loadedMessage }}</p>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <Card title="Clientes" :subTitle="data == null ? 0 :data.TotalClients" description="registados" icon="mdi-account-multiple"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="Pacotes" :subTitle="data == null ? 0 :data.TotalPackage" description="registados" icon="mdi-briefcase" avatarColor="secondary"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="A expirar" :subTitle="data == null ? 0 :data.InvoicesPassed" description="faturas" icon="mdi-receipt-text" avatarColor="warning"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="Totais em dívida" :subTitle="data == null ? 0 :data.InvoicesDebt" description="euros" icon="mdi-currency-eur" avatarColor="#948F8F"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="auto">
            <Card title="Gasto mês (horas)" :subTitle="data == null ? 0 : data.month_hours " description="" icon="mdi-currency-eur" avatarColor="green darken-4"/>
          </v-col>
        </v-row>
        <v-row v-if="dashboardLoaded">
          <v-col cols="12" md="2">
            <v-select
              dense outlined
              v-model="filters.year"
              :items="getYears"
              @change="loadGraph"
              label="Ano"
            />
          </v-col>
          <v-col cols="12">
            <HoursMonth :data="data"/>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>
<script>
import Dashboard from "@/api/Dashboard.js"
import Card from '@/components/ui/Card.vue';
import Quotes from '@/api/Quotes'
import HoursMonth from "@/components/dashboard/HoursMonth.vue"

export default{
  components:{
    Card,
    HoursMonth
  },
  data(){
    return{
      loading: false,
      data: null,
      name: '',
      loadedMessage: '',
      loaderMessages: [],
      dashboardLoaded: false,
      filters:{
        year: null
      }
    }
  },
  beforeCreate(){

    
    
  },
  async mounted(){
    var currentTime = new Date()

    var year = currentTime.getFullYear()
    this.filters.year = year

    Dashboard.get(this.filters)
    .then((data) => {
      this.data = data.data
      this.dashboardLoaded = true
    }).catch(() => {

    })
    
    this.loading = true
    this.name = this.$root.session.name
    await Quotes.getAll().then(response => {                    
        if(response.data.length != 0){
          this.loaderMessages = []
          response.data.forEach(element => {
            this.loaderMessages.push(element.quote)
          });
        }

    })

    if(this.loaderMessages.length > 0){
      let n = Math.floor(Math.random() * this.loaderMessages.length);
      this.loadedMessage = this.loaderMessages[n]

      setInterval(this.changeMessage, 5000);
    }
    this.loading = false
  },
  methods:{
    loadGraph(){
      this.dashboardLoaded = false
      Dashboard.get(this.filters)
      .then((data) => {
        this.data = data.data
        this.dashboardLoaded = true
      }).catch(() => {

      })
    },
    changeMessage(){
      if(this.loaderMessages.length > 0){
        let n = Math.floor(Math.random() * this.loaderMessages.length);

        this.loadedMessage = this.loaderMessages[n];
      }
    },
  },
  computed: {
    getYears(){
      var currentTime = new Date()
      var year = currentTime.getFullYear()
      let ano_primeiro = 2023

      let ar = []
      for (let i = 2023; i <= year; i++) {
        ar.push(ano_primeiro)
        ano_primeiro++
      }
      return ar
    },
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
      ];
    }
  }
}
</script>