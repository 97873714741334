<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
      <v-container>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="Referência" name="Referência" rules="|">
                <v-text-field
                  v-model="fields.reference"
                  prepend-inner-icon="mdi-form-textbox"
                  label="Referência"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="Nome" name="Nome" rules="required">
                <v-text-field
                  v-model="fields.name"
                  prepend-inner-icon="mdi-form-textbox"
                  label="Nome"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-col align="center" justify="space-around">
              <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                Gravar
              </v-btn>
            </v-col>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Fornecedor gravado com sucesso
      </DialogSuccess>
    </validation-observer>
    </v-container>
    </v-card>
  </template>
  <script>
  import Suppliers from "@/api/Suppliers.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    components: {
      DialogSuccess
    },
    props: {
     supplier: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    supplier: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      fields: {
        id: null,
        reference: null,
        name: null
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/suppliers');
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          Suppliers[this.method](this.fields, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = this.$t('globals.error') + " " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  