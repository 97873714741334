import BaseApi from "./BaseApi";

export default class Quotes extends BaseApi{
    construct() {}

    static async search(params) {
        return await Quotes.getAxiosInstance().get(Quotes.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Quotes.getAxiosInstance().post(Quotes.getApiUrl(),params);
    }

    static async update(params) {
        return await Quotes.getAxiosInstance().put(Quotes.getApiUrl() + params.id,params);
    }

    static async delete(id) {
        return await Quotes.getAxiosInstance().delete(Quotes.getApiUrl() + id);
    }

    static async get(id){
        return await Quotes.getAxiosInstance().get(Quotes.getApiUrl() + id)
    }

    static async getAll(){
        return await Quotes.getAxiosInstance().get(Quotes.getApiUrl() + 'list')
    }

    static async find(id) {
        return await Quotes.getAxiosInstance().get(Quotes.getApiUrl() + id);
    }

    static async list(params) {
        let url = Quotes.getApiUrl() + "list";

        return Quotes.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/quotes/";
    }
}