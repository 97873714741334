<template>
    <v-card class="elevation-10">
      <v-card-title class="grey darken-3 white--text text-center">
        <v-container fluid class="text-center ma-0 pa-0">
          <span class="text-center">{{fields.name}}</span>
        </v-container>
      </v-card-title>
      <v-tabs v-model="tab" background-color="primary" fixed-tabs>
        <v-tab href="#Geral">Geral</v-tab>
        <v-tab href="#Acessos" :disabled="method == 'create' ? true : false">Acessos</v-tab>
        <v-tab v-if='this.$root.session.hasPermission(["super", "invoices.read"])' href="#Faturas" :disabled="method == 'create' ? true : false">Faturas</v-tab>
        <v-tab v-if='this.$root.session.hasPermission(["super", "packages.read"])' href="#Pacotes" :disabled="method == 'create' ? true : false">Pacotes</v-tab>
        <v-tab href="#Projetos" :disabled="method == 'create' ? true : false">Projetos</v-tab>
        <v-tab href="#Alertas" :disabled="method == 'create' ? true : false">Alertas</v-tab>
      </v-tabs>
      
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item value="Geral">
            <validation-observer ref="form" v-slot="{ invalid }">      
              <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                  ></v-progress-linear>
                <fieldset :disabled="loading">
                  <v-row>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                        <v-text-field
                          v-model="fields.name"
                          prepend-inner-icon="mdi-text-box"
                          label="Nome"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="nif" name="Nif" rules="required">
                        <v-text-field
                          v-model="fields.nif"
                          prepend-inner-icon="mdi-barcode"
                          label="Nif"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="telephone" name="Telefone" rules="required">
                        <v-text-field
                          v-model="fields.telephone"
                          prepend-inner-icon="mdi-phone"
                          label="Telefone"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required">
                        <v-text-field
                          v-model="fields.email"
                          prepend-inner-icon="mdi-email"
                          label="Email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>            
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" vid="address" name="Endereço" rules="required">
                        <v-text-field
                          v-model="fields.address"
                          prepend-inner-icon="mdi-map-marker"
                          label="Endereço"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="domain" name="Domínio" rules="required">
                        <v-text-field
                          v-model="fields.dominio"
                          prepend-inner-icon="mdi-link"
                          label="Domínio"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="fields.obs"
                        dense outlined
                        label="Observações"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                        Gravar
                      </v-btn>
                    </v-col>
                  </v-row>
                </fieldset>
              </v-form>
              <DialogSuccess :opened="success" @on-ok="onSuccess" >
                  Cliente gravado com sucesso!
              </DialogSuccess>
            </validation-observer>
          </v-tab-item>   
          <v-tab-item value="Acessos">
            <ListUsers :tenant="fields.tenant"/>>
          </v-tab-item>
          <v-tab-item value="Faturas">
            <ListInvoices v-if="tab == 'Faturas'" :tenant="fields.tenant"/>
            
          </v-tab-item>
          <v-tab-item value="Pacotes">
            <ListPackages v-if="tab == 'Pacotes'" :tenant="fields.tenant"/>
          </v-tab-item>
          <v-tab-item value="Projetos">
            <ListProjects v-if="tab == 'Projetos'" :tenant="fields.tenant"/>
          </v-tab-item>
          <v-tab-item value="Alertas">
            Under construction Alertas
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
</template>
<style>
.v-tab{
  color: white !important;
}
.v-tab--active{
  color: rgb(148, 143, 143) !important;
}
</style>
<script>

import ListInvoices from '@/components/clients/invoices/ListInvoices.vue';
import ListUsers from '@/components/clients/accesses/ListUsers.vue';
import ListPackages from '@/components/clients/packages/ListPackages.vue';
import ListProjects from '@/components/clients/projects/ListProjects.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Client from "@/api/Client.js";

export default {
  components: {
    DialogSuccess,
    ListInvoices,
    ListUsers,
    ListPackages,
    ListProjects
  },
  props:{
    method: String
  },
  watch: { 
    client: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;


      }
    }
  },
  beforeCreate(){
  },
  data: () => ({
      tab: null,
      success: false, 
      loading: false,
      fields: {
          id: null,
          name: '',
          nif: '',
          telephone: '',
          email: '',
          address: '',
          dominio: '',
          tenant: '',
          obs: null
      }
  }),
  mounted(){
    if(this.method == 'update'){
      Client.find(this.$route.params.id).then(({data}) => {
          this.fields.id = data.id
          this.fields.name = data.name
          this.fields.nif = data.nif
          this.fields.telephone = data.telephone
          this.fields.email = data.email
          this.fields.address = data.address
          this.fields.dominio = data.domain
          this.fields.tenant = data.tenant
          this.fields.obs = data.obs
      });
    }
    
  },
  methods:{
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/clients');
    },
    submit() {
      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        Client[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
  