import BaseApi from "./BaseApi";

export default class Dashboard extends BaseApi{
    construct() {}

    static async get(params) {
        return await Dashboard.getAxiosInstance().get(Dashboard.getApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/dashboard/";
    }

}