<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-domain</v-icon> <span>Gestão de citações</span>
        </h1>
  
        <div class="mt-4">
            <Quote :method="$route.name"/>
        </div>
    </section>
  </template>
  <script>
  import Quote from '@/components/quotes/quote/Quote'
  
  
  export default {
    components: {   
        Quote     
    },
    beforeCreate(){
      document.title = "Atlas - Gestão de citações";
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "quotes.read"])) {
        this.$router.push('/home');
      }
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Listagem de citações',
            disabled: false,
            to: '/internal_management/quotes',
            exact: true,
          },
          {
            text: 'Gestão de citações',
            disabled: true,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  