import BaseApi from "./BaseApi";
export default class Invoice extends BaseApi{
    construct() {}

    static async search(params, tenant) {
        return await Invoice.getAxiosInstance().get(Invoice.getFullApiUrl(tenant),{params: params});
    }

    static async create(params, tenant) {
        let url = Invoice.getFullApiUrl(tenant)
        
        return await Invoice.getAxiosInstance().post(url,params);
    }

    static async download(id, tenant) {
        let url = Invoice.getFullApiUrl(tenant) + 'download/' + id
        
        return await Invoice.getAxiosInstanceDownload(url)
    }

    static async delete(params, tenant) {
        let url = Invoice.getFullApiUrl(tenant) + params.id
        
        return await Invoice.getAxiosInstance().delete(url);
    }

    static async update(params, tenant) {
        let url = Invoice.getFullApiUrl(tenant) + params.get('id')
        
        return await Invoice.getAxiosInstance().put(url,JSON.parse(params.get('invoice')));
    }

    static async list(params) {
        let url = Invoice.getApiUrl() + "list";

        return Invoice.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/invoices/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/invoices/";
    }
}