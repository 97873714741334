<template>
    <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>Gestão de colaboradores</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  @click="openDialog"
                  v-if="$root.session.hasPermission(['super', 'collaborators.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-tabs v-model="tabs" fixed-tabs dark>
          <v-tab :key="1">
            Geral
          </v-tab>
          <v-tab :key="2">
            Horas
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              class="mt-16 elevation-10"
              locale="pt-pt"
            >

              <template v-slot:item.supplier="{item}">
                {{item.supplier == null ? '-' : item.supplier.name }}
              </template>

              <template v-slot:item.actions="{item}">
                <IconView
                  class="mr-2"
                  @click="viewCollaborator(item)"
                />
                <IconRemove
                  v-if="$root.session.hasPermission(['super', 'collaborators.delete'])"
                  class="mr-2"
                  @on-submit="deleteCollaborator(item)"
                />
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <WorkersHours />
          </v-tab-item>
        </v-tabs-items>
        
        
        <AddCollaborator ref="AddCollaborator" @reloadCollaborators="searchCollaborator" />
        <EditCollaborator ref="EditCollaborator" @reloadCollaborators="searchCollaborator" :selectedCollaborator="selectedCollaborator" method="update"/>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetCollaborator">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchCollaborator">
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-account-details"
                label="Código"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.display_name"
                prepend-inner-icon="mdi-account-details"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>
            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Collaborator from "@/api/Collaborator.js";
import AddCollaborator from "@/components/collaborators/AddCollaborator"
import EditCollaborator from "@/components/collaborators/AddCollaborator"
import WorkersHours from '@/components/collaborators/datatables/WorkersHours.vue'

export default {
  components: {
    IconRemove,
    IconView,
    AddCollaborator,
    EditCollaborator,
    WorkersHours
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchCollaborator()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
  },
  data: () => ({
    tabs: 0,
    selectedCollaborator: null,
    headers: [
      {
        text: 'Nome',
        sortable: false,
        value: 'full_name',
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email',
      },
      {
        text: 'Contacto',
        sortable: false,
        value: 'telephone',
      },
      {
        text: 'Fornecedor',
        sortable: false,
        value: 'supplier',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      name: null,
      display_name:null,
      user_id:null,
    },
    users:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "collaborators.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    openDialog(){
      this.$refs.AddCollaborator.openDialog()
    },
    fillBaseData() {

      if(localStorage["collaborators-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["collaborators-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchCollaborator(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["collaborators-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Collaborator.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetCollaborator()
    {
      this.filter = {};

      this.searchCollaborator();
    },
    viewCollaborator(item){
      this.selectedCollaborator = item
      this.$refs.EditCollaborator.openDialog()
    },
    deleteCollaborator(item){
        Collaborator.delete(item.id)
        .then(() => {
          this.searchCollaborator();
        });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Gestão Interna',
          disabled: false,
          to: '#',
          exact: true,
        },
        {
          text: 'Gestão de Colaboradores',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
