<template>
    <section class="mt-16">
      
      <PackagesComponent />
      </section>
</template>
<script>
    
import PackagesComponent from '../components/packages/PackagesComponent.vue';

export default{
    components: {
    PackagesComponent
}
}
</script>