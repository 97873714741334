import BaseApi from "./BaseApi";

export default class ProjectComments extends BaseApi{
    construct() {}

    static async create(params, tenant) {
        let url = ProjectComments.getFullApiUrl(tenant)
        
        return await ProjectComments.getAxiosInstance().post(url,params);
    }

    static async delete(params, tenant) {
        return await ProjectComments.getAxiosInstance().delete(ProjectComments.getFullApiUrl(tenant) + params.id);
    }

    static async getComments(id, tenant){
        let url = ProjectComments.getFullApiUrl(tenant) + id
        
        return ProjectComments.getAxiosInstance().get(url)
    }
    
    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/projectscomments/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/projectscomments/";
    }

}