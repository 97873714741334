import BaseApi from "./BaseApi";

export default class ClientUsers extends BaseApi{
    construct() {}

    static async search(params, tenant) {
        return await ClientUsers.getAxiosInstance().get(ClientUsers.getFullApiUrl(tenant),{params: params});
    }

    static async create(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant)
        
        return await ClientUsers.getAxiosInstance().post(url,params);
    }

    static async update(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant) + params.id
        
        return await ClientUsers.getAxiosInstance().put(url,params);
    }

    static async delete(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant) + params.id
        
        return await ClientUsers.getAxiosInstance().delete(url,params);
    }

    static async getPackages(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'packages'

        return await ClientUsers.getAxiosInstance().get(url, {params:params});
    }

    static async listPackages(tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'packages/list'

        return await ClientUsers.getAxiosInstance().get(url);
    }

    static async addPackage(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'packages'

        return await ClientUsers.getAxiosInstance().post(url, params);
    }

    static async deletePackage(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'packages/' + params.id

        return await ClientUsers.getAxiosInstance().delete(url, params);
    }

    static async updatePackage(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'packages/' + params.id

        return await ClientUsers.getAxiosInstance().put(url, params);
    }

    static async getProjects(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'projects'

        return await ClientUsers.getAxiosInstance().get(url, {params:params});
    }

    static async listProjects(tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'projects/list'

        return await ClientUsers.getAxiosInstance().get(url);
    }

    static async addProject(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'projects'

        return await ClientUsers.getAxiosInstance().post(url, params);
    }

    static async updateProject(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'projects/' + params.id

        return await ClientUsers.getAxiosInstance().put(url, params);
    }

    static async deleteProject(params, tenant){
        let url = ClientUsers.getFullApiUrl(tenant) + 'projects/' + params.id

        return await ClientUsers.getAxiosInstance().delete(url, params);
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/clientusers/";
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/clientusers/";
    }

}