<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="label != ''"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{ label }}
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>                        
                        <span class="text-h5">Registar user</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_invoice" v-slot="{ invalid }">      
                    <v-form class="mb-16" @submit.prevent="addUser">
                        <fieldset :disabled="loading">                        
                        <v-row>
                            <v-col cols="6">
                                <validation-provider v-slot="{ errors }" vid="name" name="name" rules="required">
                                    <v-text-field
                                    v-model="user.name"
                                    :error-messages="errors"
                                    label="Nome"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="6">
                                <validation-provider v-slot="{ errors }" vid="email" name="email" rules="required">
                                    <v-text-field
                                    v-model="user.email"
                                    :error-messages="errors"
                                    label="Email"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <validation-provider v-slot="{ errors }" vid="telephone" name="telephone" rules="required">
                                    <v-text-field
                                    v-model="user.telephone"
                                    :error-messages="errors"
                                    label="Telefone"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="6">
                                <v-switch label="Ativo" v-model="user.is_active" inset></v-switch>
                            </v-col>
                        </v-row>   
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-checkbox 
                                    label="Todos"
                                    v-model="allSelected"
                                    @change="selectAllProjects"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="10">
                                <v-select
                                    v-model="user.projectsList"
                                    :items="projectsList"
                                    item-text="name"
                                    item-value="id"
                                    chips
                                    label="Projetos"
                                    @change="calculateSelected"
                                    multiple
                            ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>                            
                            <v-col cols="12" md="12">
                                <p class="text-caption mb-n4">Permissões de faturação:</p>
                                <v-checkbox
                                    label="Ver todas as faturas"
                                    persistent-hint
                                    hint="permite que o utilizador tenha acesso a todas as faturas, mesmo de projetos que não sejam dele"
                                    v-model="user.see_all_invoices"
                                >
                                </v-checkbox>
                                <v-checkbox
                                    v-if="user.see_all_invoices == false && user.projectsList.length != 0"
                                    label="Ver faturas de seus projetos"
                                    persistent-hint
                                    hint="permite que o utilizador tenha acesso às faturas dos projetos que ele está associado"
                                    v-model="user.see_some_invoices"
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                    <v-text-field
                                    v-model="user.password"
                                    v-if="method == 'create'"
                                    label="Password"
                                    type="password"
                                    ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="user.password"
                                    label="Password"
                                    type="password"
                                ></v-text-field>
                            </v-col>
                        </v-row>                 
                        <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Gravar
                                </v-btn>
                            </v-col>
                            </v-row>
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            User gravado com sucesso
        </DialogSuccess>
    </v-row>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ClientUsers from "@/api/ClientUsers.js"

export default {
    components:{
        DialogSuccess,
    },
    props:{
        label: {
            Type: String,
            default: ''
        },
        tenant:{
            Type: String,
            default: null
        },
        method:{
            Type: String,
            default: 'create'
        },
        selectedUser:{
            default: null
        }
    },
    mounted(){
        ClientUsers.listProjects(this.tenant).then(response => {
            this.projectsList = response.data
            
        });
    },
    data(){
        return{
            allSelected: false,
            projectsList: null,
            dialog: false,
            loading: false,         
            success: false, 
            user:{
                name: '',
                email: '',
                password: '',
                is_active: 0,
                telephone: '',
                id: '',
                projectsList: [],
                see_all_invoices: true,
                see_some_invoices: false
            }
        }
    },
    watch:{
        selectedUser:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                this.user.name = val.name
                this.user.email = val.email
                this.user.password = ''
                this.user.is_active = val.is_active
                this.user.telephone = val.telephone
                this.user.id = val.id
                this.user.projectsList = val.projectsList
                this.user.see_all_invoices = val.see_all_invoices
                this.user.see_some_invoices = val.see_some_invoices
        
                if(this.user.projectsList.length == this.projectsList.length)
                    this.allSelected = true
                }
            }
    },
    methods:{
        resetField(){
            this.user = {
                name: '',
                email: '',
                password: '',
                is_active: 0,
                telephone: '',
                id: '',
                projectsList: [],
                see_all_invoices: true,
                see_some_invoices: false
            }
        },
        calculateSelected(){
            if(this.user.projectsList.length == this.projectsList.length)
                this.allSelected = true
            else
                this.allSelected = false
        },
        selectAllProjects(v){
            if(v == true){
                this.user.projectsList = []
                this.projectsList.forEach(element => {
                    this.user.projectsList.push(element.id)
                });
            }else{
                if(this.user.projectsList.length == this.selectedUser.projectsList.length)
                    this.user.projectsList = []
                else
                    this.user.projectsList = this.selectedUser.projectsList
            }
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadUsers')
        },
        openDialog(){
            this.dialog = true
        },
        addUser(){
            if(this.user.password != '')
                this.user.password = this.$encryptValue(this.user.password)
            ClientUsers[this.method](this.user, this.tenant).then(() => {
        
            this.success = true;

            this.loading = false;

            this.resetField()

            }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

            });
            this.user.password = ''
        },
    }
}
</script>