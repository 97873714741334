<template>
    <v-container fluid v-if="!loading">
        <div id="chart">
            <VueApexCharts type="bar" height="350" :options="chartOptions" :series="getSeries"></VueApexCharts>
        </div>
    </v-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default{
    name:"HoursMonth",
    components:{VueApexCharts},
    props: ['data'],
    mounted(){
        this.loading = false
    },
    computed:{
        getSeries(){
            return [{
                name: 'Horas',
                data: this.data.hours_by_month
            }]
        }
    },
    data(){
        return{
            loading: true,
            chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val.toFixed(2) + "h";
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            
            xaxis: {
              categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
              position: 'top',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              tooltip: {
                enabled: true,
              }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val.toFixed(2) + "h";
                }
              }
            
            },
            title: {
              text: 'Horas gastas por mês',
              floating: true,
              offsetY: 330,
              align: 'center',
              style: {
                color: '#444'
              }
            }
          },
          
          
        
        }
    }
}
</script>