import BaseApi from "./BaseApi";

export default class PackageComments extends BaseApi{
    construct() {}

    static async create(params, tenant) {
        let url = PackageComments.getFullApiUrl(tenant)
        
        return await PackageComments.getAxiosInstance().post(url,params);
    }
    
    static async delete(params, tenant) {
        return await PackageComments.getAxiosInstance().delete(PackageComments.getFullApiUrl(tenant) + params.id);
    }

    static async getComments(id, tenant){
        let url = PackageComments.getFullApiUrl(tenant) + id
        
        return PackageComments.getAxiosInstance().get(url)
    }
    
    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/packagescomments/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/packagescomments/";
    }

}