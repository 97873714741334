<template>
    <section class="mt-16">
      
      <ListCollaborators />
      </section>
</template>
<script>
    
import ListCollaborators from '../components/collaborators/ListCollaborators.vue';

export default{
    components: {
    ListCollaborators
}
}
</script>