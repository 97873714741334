<template>
    <div>
    <validation-observer ref="form" v-slot="{ invalid }">
        <v-form class="mb-16" @submit.prevent="addQuote">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
            ></v-progress-linear>
            <fieldset :disabled="loading">
                <v-card v-if="!loading">
                    <v-toolbar
                        flat
                        color="primary"
                        dark
                        >
                        <v-toolbar-title>Informação de citação</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>                        
                        <v-row>
                            <v-col cols="12" md="12">
                                <validation-provider v-slot="{ errors }" vid="name" name="name" rules="required">
                                    <v-text-field
                                        v-model="fields.quote"
                                        prepend-inner-icon="mdi-text-box"
                                        label="Citação"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6" align="center" justify="space-around">
                        <v-btn :disabled="invalid" depressed color="primary" type="submit">
                            Gravar
                        </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </fieldset>
        </v-form>
    </validation-observer>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{ successMessage }}
        </DialogSuccess>
    </div>
</template>
<script>
import Quotes from "@/api/Quotes.js"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    components:{
        DialogSuccess
        //Select,
    },
    props:{
        method: String
    },
    watch:{
        '$route.name':{
            immediate: true,
            handler(val){
                if(val == 'create')
                    this.resetFields()
            }
        }
    },
    data(){
        return{
            successMessage: '',
            success: false,
            loading: true,
            fields:{
                quote: '',
            }
        }
    },
    async mounted(){
        this.fillBaseData()


        this.loading = false 
    },
    methods:{
        resetFields(){
            this.fields = {
                quote: '',
            }
        },
        addQuote(){
            Quotes[this.method](this.fields).then(() => {
                this.successMessage = "Sucesso ao gravar citação"
                this.success = true;

                this.loading = false;

                if(this.$route.name == 'create')
                    this.$router.push('/internal_management/quotes')

            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        onSuccess(){
            this.success = false
        },
        fillBaseData(){
            if(this.$route.name == 'update'){
                Quotes.get(this.$route.params.id).then(response => {
                    
                    this.fields = response.data

                })
            }
        },
        reloadPRD(){
            this.fillBaseData()
        }
    }
}
</script>