<template>
    <section class="mt-16">
      
      <ClientsComponent />
      </section>
</template>
<script>
    
import ClientsComponent from '@/components/clients/ClientsComponent.vue';

export default{
    components: {
        ClientsComponent
    }
}
</script>