<template>
    <v-row>
        <v-col
      cols="12"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="selectedDate"
            :label="label"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="selectedDate"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-spacer></v-spacer>

    </v-row>
</template>
<script>
    export default{
        props: ['date', 'label'],
        data(){
            return{
                menu: false,
                selectedDate: ''
            }
        },
        watch:{
          date:{
            immediate: true,
            handler(val){
              this.selectedDate = val
            }
          },
          selectedDate:{
            immediate: true,
            handler(val){
              this.$emit('changed', val)
            }
          }
        }
    }
</script>