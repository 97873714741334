import BaseApi from "./BaseApi";

export default class Suppliers extends BaseApi{
    construct() {}

    static async search(params) {
        return await Suppliers.getAxiosInstance().get(Suppliers.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Suppliers.getAxiosInstance().post(Suppliers.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Suppliers.getAxiosInstance().put(Suppliers.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Suppliers.getAxiosInstance().delete(Suppliers.getApiUrl() + id);
    }

    static async find(id) {
        return await Suppliers.getAxiosInstance().get(Suppliers.getApiUrl() + id);
    }

    static async list(params) {
        let url = Suppliers.getApiUrl() + "list";

        return Suppliers.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/suppliers/";
    }
}