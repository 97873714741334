<template>
    <div>
      <h1 class="primary--text mt-4 ml-4 mb-n12">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>Utilizadores</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  @click="openDialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>


          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="filter.active"
                class="mt-12 mb-n12"
                color="success"
                @change="searchUsers"
                label="Ativos"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>


        </v-container>
        
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >

          <template v-slot:item.expires_at="{item}">
            {{ formatData(item.expires_at) }}
            <Tooltip
              v-if="showWarningIcon(item.expires_at)"
              class="my-4 mr-4"
              color="red"
              text="Data expirada ou a expirar"
              top
            >
            <v-icon
              color="red"
              >mdi-alert-circle-outline</v-icon>
          </Tooltip>
          </template>


          <template v-slot:item.paid="{item}">
            <Tooltip
              v-if="item.paid"
              class="my-4 mr-4"
              color="green"
              text="Paga"
              top
            >
            <v-icon
              color="success"
            >mdi-check</v-icon>
            </Tooltip>
            <Tooltip
              v-else
              class="my-4 mr-4"
              color="red"
              text="Por pagar"
              top
            >
            <v-icon
              color="red"
              >mdi-alert-circle-outline</v-icon>
            </Tooltip>

          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewUser(item)"
            />
            <IconRemove
              
              class="mr-2"
              @on-submit="deleteUser(item)"
            />
          </template>
        </v-data-table>
        <AddUsers  ref="AddUser" :tenant="tenant" @reloadUsers="searchUsers" />
        <EditUsers ref="EditUser" :tenant="tenant" @reloadUsers="searchUsers" :selectedUser="selectedUser" method="update"/>
      </div>
    </div>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import ClientUsers from "@/api/ClientUsers.js"
import Tooltip from "@/components/ui/Tooltip"
import AddUsers from "@/components/clients/accesses/AddUsers"
import EditUsers from "@/components/clients/accesses/AddUsers"

export default {
  components: {
    IconRemove,
    IconView,
    Tooltip,
    AddUsers,
    EditUsers
  },
  props:['tenant'],
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchUsers()}, 10);
      },
      deep: true,
    },
  },
  data: () => ({
    selectedUser: null,
    headers: [
      {
        text: 'Nome',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email',
      },
      {
        text: 'Telefone',
        sortable: false,
        value: 'telephone',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      active: true,
      paid:false,
    },
    users:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "clients.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    openDialog(){
      this.$refs.AddUser.openDialog()
    },
    showWarningIcon(dt){
      var fDt = new Date(dt);
      var today = new Date();
      var Difference_In_Time = fDt.getTime() - today.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if(Difference_In_Days <= 7)
        return true
      return false
    },
    formatData(dt){
      return dt.split(' ')[0]
    },
    fillBaseData() {

      if(localStorage["clientusers-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["clientusers-" + this.$root.session.id]);

        this.filter = data.filter;

        if(this.filter.active == undefined || this.filter.active == null)
          this.filter.active = true

        this.options = data.options;
      }
    },
    searchUsers(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};
      
      let request = {
        active: this.filter.active,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["clientusers-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      ClientUsers.search(request, this.tenant).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetUsers()
    {
      this.filter = {};

      this.searchUsers();
    },
    viewUser(item){
      this.selectedUser = item
      this.$refs.EditUser.openDialog()
    },
    deleteUser(item){
      ClientUsers.delete(item, this.tenant)
        .then(() => {
          this.searchUsers();
        });
    }
  },
  computed: {
    
  }
};
</script>
