<template>
    <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-note</v-icon> <span>Gestão de Roles</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/admin/settings/roles/create"
                  v-if="$root.session.hasPermission(['super', 'roles.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16 elevation-10"
          locale="pt-pt"
        >

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewRole(item)"
            />
            <IconRemove
              v-if="item.users.length == 0"
              class="mr-2"
              @on-submit="deleteRole(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetRoles">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchRoles">
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-account-details"
                label="Código"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.display_name"
                prepend-inner-icon="mdi-account-details"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>
            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <AddRole ref="AddRole" @reloadRoles="searchRoles"/>

    </div>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Role from "@/api/Role.js";
import AddRole from '@/components/roles/main/AddRole.vue';
//import EditRole from '@/components/roles/main/AddRole.vue';

export default {
  components: {
    IconRemove,
    IconView,
    AddRole
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchRoles()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Atlas - Gestão de Roles";

  },
  data: () => ({
    headers: [
      {
        text: 'Código',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Nome',
        sortable: false,
        value: 'display_name',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      name: null,
      display_name:null,
      user_id:null,
    },
    users:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "roles.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    fillBaseData() {

      if(localStorage["roles-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["roles-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchRoles(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["roles-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Role.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetRoles()
    {
      this.filter = {};

      this.searchRoles();
    },
    viewRole(item){
      this.$router.push('/admin/settings/roles/' + item.id);
    },
    deleteRole(item){
      Role.delete(item.id)
        .then(() => {
          this.searchRoles();
        });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Gestão Interna',
          disabled: false,
          to: '#',
          exact: true,
        },
        {
          text: 'Gestão de Roles',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
