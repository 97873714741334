import BaseApi from "./BaseApi";

export default class Package extends BaseApi{
    construct() {}

    static async search(params) {
        return await Package.getAxiosInstance().get(Package.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Package.getAxiosInstance().post(Package.getApiUrl(),params);
    }

    static async update(params) {
        return await Package.getAxiosInstance().put(Package.getApiUrl() + params.id,params);
    }
    
    static async delete(id) {
        return await Package.getAxiosInstance().delete(Package.getApiUrl() + id);
    }

    static async find(id) {
        return await Package.getAxiosInstance().get(Package.getApiUrl() + id);
    }

    static async list(params) {
        let url = Package.getApiUrl() + "list";

        return Package.getAxiosInstance().get(url,{params: params});
    }

    static async getAll(){
        let url = Package.getApiUrl()
        
        return Package.getAxiosInstance().get(url)
    }

    static async exportClientPackage(id, tenant){
        let url = Package.getApiUrl() + 'export-info/' + id + '/' + tenant
        
        return Package.getAxiosInstance().get(url)
    }



    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/packages/";
    }

}