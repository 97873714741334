<template>
    <section class="mt-16">
        <QuotesList />
    </section>
</template>
<script>
    
import QuotesList from '@/components/quotes/QuotesList.vue';

export default{
    components: {
        QuotesList
    }
}
</script>