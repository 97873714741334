<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            :fullscreen="method == 'addProject' ? false : false"
            persistent
            max-width="800px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="label != ''"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{ label }}
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>
                        <span class="text-h5" v-if="method=='addProject'">Registar projeto</span>
                        <span class="text-h5" v-else>Atualizar projeto</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="commentObject.package_id = null;dialog = false;"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_project" v-slot="{ invalid }">      
                    <v-form class="mb-16" @submit.prevent="addProject" >
                        <fieldset :disabled="loading">                        
                        <v-row>
                            <v-col cols="12">
                                <validation-provider v-slot="{ errors }" vid="name" name="name" rules="required">
                                    <v-text-field
                                    v-model="myProject.name"
                                    :error-messages="errors"
                                    label="Nome"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>   
                            <v-col cols="3">                                
                                <validation-provider v-slot="{ errors }" vid="started_at" name="started_at" rules="required">
                                    <DatePicker :error-messages="errors" label="Inicio" @changed="startedAtChanged" :date="myProject.started_at" return-object/>
                                </validation-provider>
                            </v-col>   
                            <v-col cols="3">                                
                                <validation-provider v-slot="{ errors }" vid="project_deadline" name="project_deadline" rules="required">
                                    <DatePicker :error-messages="errors" label="Deadline" @changed="projectDeadlinChanged" :date="myProject.project_deadline" return-object/>
                                </validation-provider>
                            </v-col>    
                            <v-col cols="3">
                                    <v-text-field
                                        v-model="myProject.hours_spent"
                                        label="Horas gastas"
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                    <v-text-field
                                        v-model="myProject.done_percentage"
                                        label="% feita"
                                    ></v-text-field>
                            </v-col>
                            <v-col cols="12" >
                                <v-select
                                    v-model="myProject.developers"
                                    :items="developers"
                                    item-text="name"
                                    item-value="id"
                                    chips
                                    label="Programadores"
                                    multiple
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                <v-textarea
                                name="input-7-1"
                                v-model="myProject.description"
                                :error-messages="errors"
                                label="Descriçao"
                                ></v-textarea>
                            </validation-provider>
                            </v-col>
                        </v-row>
                             
                        <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Gravar
                                </v-btn>
                            </v-col>
                            </v-row>
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>

                <v-card-title class="primary white--text">
                    Comentários
                </v-card-title>
                <v-card-text v-if="method != 'addProject'">
                    <validation-observer ref="form_comments" v-slot="{ invalid }">      
                        <v-form class="mb-16" @submit.prevent="addComment">
                            <fieldset :disabled="loading">
                                <v-row>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }" vid="comment" name="comment" rules="required">
                                            <v-textarea
                                            v-model="commentObject.comment"
                                            :error-messages="errors"
                                            label="Comentário"
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3">                                        
                                        <v-checkbox label="Queimar horas?" v-model="commentObject.isBurnHours"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="9" v-if="commentObject.isBurnHours">
                                        <validation-provider v-slot="{ errors }" vid="burned_hours" name="burned_hours" rules="required">
                                            <v-text-field
                                            v-model="commentObject.burned_hours"
                                            :error-messages="errors"
                                            label="Queimar horas"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="commentObject.isBurnHours">
                                        <v-autocomplete
                                            v-model="commentObject.collaborator_id"
                                            :items="collaborators"
                                            outlined
                                            dense
                                            item-text="full_name"
                                            item-value="id"
                                            label="Colaborador"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="commentObject.isBurnHours">
                                        <v-select
                                            label="Associar a package"
                                            :items="getPackageList"
                                            clearable
                                            :item-text="item => item.name + ' (' + item.hours_left + 'h)'"
                                            item-value="id"
                                            v-model="commentObject.package_id"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="commentObject.isBurnHours">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="commentObject.comment_date"
                                                label="Escolha a data"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="commentObject.comment_date"
                                            :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                            @input="menu2 = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="auto" v-if="commentObject.package_id != null">
                                        <span class="text-caption">Por gastar:</span> {{packageChoosed(commentObject.package_id)}}
                                    </v-col>
                                    <v-col cols="12" align="center" justify="space-around">
                                        <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                        Comentar
                                        </v-btn>
                                    </v-col>
                                </v-row>                                
                            </fieldset>
                        </v-form>
                    </validation-observer>
                    <div :key="reloadComments">
                    <v-timeline dense v-for="c in myProject.comments" :key="c.id">
                        <v-timeline-item size="large" v-if="c.landlord_user == null">
                            <template v-slot:icon>
                                <v-avatar><v-icon color="white">mdi-account</v-icon></v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span>{{ c.tenant_user.name }}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>{{formatData(c.created_at)}}</v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item size="large" v-else>
                            <template v-slot:icon>
                                <v-avatar>
                                    <v-icon color="white" v-if="c.burned_hours == null">mdi-account</v-icon>
                                    <v-icon color="white" v-else>mdi-fire-circle</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span>{{ c.landlord_user.name }}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>
                                    <v-row v-if="c.burned_hours != null">
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Horas queimadas: {{c.burned_hours}}</p>
                                        </v-col>
                                        <v-col cols="12" class="mt-n3" v-if="c.package != null">
                                            <p class="font-weight-bold">Package: {{c.package.name}}</p>
                                        </v-col>
                                        <v-col cols="12" class="mt-n3" v-if="c.collaborator != null">
                                            <p class="font-weight-bold">Colaborador: {{c.collaborator.full_name}}</p>
                                        </v-col>
                                        <v-col cols="12" class="mt-n3" v-if="c.comment_date != null">
                                            <p class="font-weight-bold">Data: {{c.comment_date.split(' ')[0]}}</p>
                                        </v-col>
                                    </v-row>
                                    {{formatData(c.created_at)}}
                                </v-card-text>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <IconRemove
                                                class="mr-2"
                                                @on-submit="deleteComment(c)"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </div>
                </v-card-text>

            </v-card>
        </v-dialog>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Projeto adicionado com sucesso ao cliente
        </DialogSuccess>
    </v-row>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import DatePicker from '@/components/ui/DatePicker'
import ClientUsers from "@/api/ClientUsers.js"
//import Project from "@/api/Project.js"
import ProjectComments from "@/api/ProjectComments.js"
import User from "@/api/User.js"
import Collaborator from "@/api/Collaborator.js"
import IconRemove from '@/components/ui/IconRemove.vue';

export default {
    components:{
        DialogSuccess,
        DatePicker,
        IconRemove
    },
    props:{
        label: {
            Type: String,
            default: ''
        },
        tenant:{
            Type: String,
            default: null
        },
        method:{
            Type: String,
            default: 'addProject'
        },
        selectedProject:{
            default: null
        }
    },
    data(){
        return{
            menu2: false,
            developers: [],
            packagesList: [],
            collaborators: [],
            reloadComments: 0,
            commentObject: {
                comment: '',
                collaborator_id: null,
                burned_hours: '',
                isBurnHours: false,
                id_package: null,
                package_id: null
            },
            dialog: false,
            loading: false,         
            success: false, 
            myProject:{
                id: null,
                name: '',
                description: '',
                started_at: null,
                project_deadline: null,
                hours_spent: null,
                comments: null,
                developers: null,
                done_percentage: null
            },
        }
    },
    beforeCreate(){
        User['getDevelopers'](this.$route.params.id).then((response) => {
            this.developers = response.data
        }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

        });
    },
    mounted(){
        this.loading = true
        this.fillBaseData()
    },
    watch:{
        selectedProject:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                this.myProject.name = val.name
                this.myProject.id = val.id
                this.myProject.description = val.description
                
                if(val.started_at != null)
                    this.myProject.started_at = (val.started_at != '' || val.started_at != null) ? val.started_at.split(' ')[0] : null
                
                if(val.project_deadline != null)
                    this.myProject.project_deadline = (val.project_deadline != '' || val.project_deadline != null || val.project_deadline != undefined) ? val.project_deadline.split(' ')[0] : null
                
                this.myProject.hours_spent = val.hours_spent
                this.myProject.id = val.id
                this.myProject.comments = val.comments
                this.myProject.developers = val.developers
                this.myProject.done_percentage = val.done_percentage

                console.log(this.myProject.comments)
            }
        }
    },
    methods:{
        deleteComment(comment){
            ProjectComments.delete(comment, this.tenant)
                .then(() => {
                    let i = 0
                    let pos = -1
                    
                    this.myProject.comments.forEach(element => {
                        if(element.id == comment.id){
                            pos = i
                        }
                        i++
                    });
                    if(pos != -1)
                        this.myProject.comments.splice(pos, 1)
                    
                    this.reloadComments++
                    this.$emit('reloadProjects')
                
                    
                });
        },
        packageChoosed(id){
            let v = 0
            this.packagesList.forEach(element => {
                if(element.id == id)
                    v = element.hours_left
            });
            return v
        },
        async fillBaseData(){
            ClientUsers.listPackages(this.tenant).then(response => {
        
                this.packagesList = response. data
                            

                this.loading = false;

            });

            Collaborator.list(this.tenant).then(response => {
        
                this.collaborators = response.data

            });
        },
        resetField(){
            this.myProject = {
                id: null,
                name: '',
                description: '',
                started_at: null,
                project_deadline: null,
                hours_spent: null,
                comments: null,
                developers: null,
                done_percentage: null
            }
        },
        startedAtChanged(t){
            this.myProject.started_at = t
        },
        projectDeadlinChanged(t){
            this.myProject.project_deadline = t
        },
        formatData(d){
            return d.split('T')[0] + ' ' + d.split('T')[1].split('.')[0]
        },
        addComment(){
            this.commentObject.id_project = this.selectedProject.id

            let valid = true
            if(this.commentObject.package_id != null){
                let left = this.packageChoosed(this.commentObject.package_id)

                if(parseFloat(this.commentObject.burned_hours) > left)
                    valid = false
            }
            
            if(valid == false){
                alert('Está a queimar horas a mais ao pacote')
                return
            }
            

            ProjectComments['create'](this.commentObject, this.tenant).then((response) => {
                let data = response.data
                this.myProject.comments.unshift(data)
                if(this.commentObject.isBurnHours){
                    if(this.myProject.hours_spent == null)
                        this.myProject.hours_spent = 0
                    this.myProject.hours_spent = parseInt(this.myProject.hours_spent) + parseInt(this.commentObject.burned_hours)
                }

                    this.commentObject = {
                    comment: '',
                    burned_hours: '',
                    isBurnHours: false,
                    id_package: null
                }

                this.fillBaseData()
                this.reloadComments++
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadProjects')
        },
        openDialog(){
            this.dialog = true
        },
        addProject(){            
            ClientUsers[this.method](this.myProject, this.tenant).then(() => {
        
                this.success = true;

                this.loading = false;

                this.resetField()

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
    },
    computed:{
        getPackageList(){
            let aux = []

            this.packagesList.forEach(element => {
                if(element.hours_left > 0)
                    aux.push(element)
            });

            return aux
        }
    }
}
</script>