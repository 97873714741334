<template>
    <div class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-comment-quote</v-icon> <span>Gestão de citações</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    fab
                    x-large
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  dark
                  fab
                  x-large
                  color="secondary"
                  to="/internal_management/quotes/create"
                  v-if="$root.session.hasPermission(['super', 'quotes.write'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-card class="mt-16">
          <v-card-title>
            <v-row>
              <v-col cols="8" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  @keyup="searchQuotes"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn right outlined small rounded class="mt-4" color="error" @click="resetQuotes">
                  <v-icon>mdi-filter</v-icon>Limpar filtros
                </v-btn>
              </v-col>
            </v-row>
            

              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :footer-props="tableFooter"
                :options.sync="options"
                class="elevation-10"
                locale="pt-pt"
              >

              <template v-slot:item.updated_at="{item}">
                  {{ formatData(item.updated_at) }}
                
              </template>
              <template v-slot:item.actions="{item}">
                  <IconView
                    v-if="$root.session.hasPermission(['super', 'quotes.read'])"
                    class="mr-2"
                    @click="viewQuote(item)"
                  />

                  <IconRemove
                    v-if="$root.session.hasPermission(['super', 'quotes.delete'])"
                    class="mr-2"
                    @on-submit="deleteQuote(item)"
                  />
              </template>

              </v-data-table>
        </v-card>
      </div>
      
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          {{ successMessage }}
      </DialogSuccess>
    </div>
</template>
<script>
import Quotes from "@/api/Quotes.js"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconView from '@/components/ui/IconView.vue';
import IconRemove from '@/components/ui/IconRemove.vue';

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchQuotes()}, 10);
      },
      deep: true,
    },
  },
  data: () => ({
    search: '',
    successMessage: '',
    success: false,
    headers: [
      { 
        text: 'Citação', 
        value: 'quote', 
        sortable: true 
      },
      {
        text: 'Última atualização',
        sortable: false,
        value: 'updated_at',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      
    },
    users:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "quotes.write", "quotes.read"])) {
      this.$router.push('/home');
    }

    this.fillBaseData();
  },
  methods:{
    onSuccess(){
        this.success = false
    },
    deleteQuote(i){
      Quotes.delete(i.id).then(() => {
          this.successMessage = "Sucesso ao eliminar citação"
          this.success = true;

          this.loading = false;

          this.fillBaseData()

      }).catch(err => {
          this.loading = false;

          if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
          }

          this.error.title = "Erro " + err.response.status;

          this.error.message = err.response.data.message;

      });
    },
    viewQuote(i){
      this.$router.push('/internal_management/quotes/' + i.id)
    },
    formatData(d){
      return new Date(d).toLocaleDateString() + ' ' +  new Date(d).toLocaleTimeString('PT-pt')
    },
    searchQuote(){
      this.options.searchQuote = this.search
      this.searchQuotes()
    },
    resetQuotes()
    {
      this.filter = {};
      this.options = {}
      this.search = ''

      this.searchQuotes();
    },
    fillBaseData() {

      if(localStorage["quotes-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["quotes-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchQuotes(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        quote: this.search,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["quotes-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Quotes.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Gestão Interna',
          disabled: false,
          to: '#',
          exact: true,
        },
        {
          text: 'Gestão de citações',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
