<template>
  <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
          <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="label != ''"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              >
              {{ label }}
              </v-btn>
          </template>
          <v-card>
              <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>
                        <span class="text-h5" v-if="method=='create'">Registar pacote</span>
                        <span class="text-h5" v-else>Editar pacote</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
              <v-card-text>
                  <validation-observer ref="form_package" v-slot="{ invalid }">      
                  <v-form class="mb-16" @submit.prevent="addPackage">
                      <fieldset :disabled="loading">
                      <v-row>
                        <v-col cols="6" md="6">
                          <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                            <v-text-field
                              v-model="fields.name"
                              prepend-inner-icon="mdi-text-box"
                              label="Nome"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6" md="6">
                          <validation-provider v-slot="{ errors }" vid="hours" name="Horas" rules="required">
                            <v-text-field
                              v-model="fields.hours"
                              prepend-inner-icon="mdi-text-box"
                              label="Horas"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" md="6">
                          <validation-provider v-slot="{ errors }" vid="price" name="Price" rules="required">
                            <v-text-field
                              v-model="fields.price"
                              prepend-inner-icon="mdi-currency-eur"
                              label="Preço"
                              clearable
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                          <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                <v-textarea
                                name="input-7-1"
                                v-model="fields.description"
                                :error-messages="errors"
                                label="Descriçao"
                                ></v-textarea>
                            </validation-provider>
                          </v-col>
                      </v-row>
                      
                      <v-row class="mt-6" align="center" justify="space-around">
                          <v-col align="center" justify="space-around">
                              <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                              Gravar
                              </v-btn>
                          </v-col>
                          </v-row>
                      </fieldset>         
                  </v-form>
                  </validation-observer>
              </v-card-text>
          </v-card>
      </v-dialog>
      
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Pacote gravado com sucesso
      </DialogSuccess>
  </v-row>
</template>
<script>
import Package from "@/api/Package.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
  components:{
      DialogSuccess,
  },
  props:{
      label: {
          Type: String,
          default: ''
      },
      tenant:{
          Type: String,
          default: null
      },
      method:{
          Type: String,
          default: 'create'
      },
      selectedPackage:{
          default: null
      }
  },
  watch:{
      selectedPackage:{
          immediate: true,
          handler(val){
              if(val == null)
                  return
              
              this.fields.name = val.name
              this.fields.id = val.id
              this.fields.description = val.description
              this.fields.hours = val.hours
              this.fields.price = val.price
          }
      }
  },
  data(){
      return{
          dialog: false,
          loading: false,         
          success: false, 
          fields:{
              description: '',
              hours: '',
              name: '',
              id: '',
              price: ''
          }
      }
  },
  methods:{
      onSuccess() {
          this.dialog = false
          this.success = false
          this.$emit('reloadPackages')
      },
      openDialog(){
          this.dialog = true
      },
      addPackage(){         
          Package[this.method](this.fields).then(() => {
      
          this.success = true;

          this.loading = false;

          }).catch(err => {
          this.loading = false;

          if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
          }

          this.error.title = "Erro " + err.response.status;

          this.error.message = err.response.data.message;

          });
      },
  }
}
</script>